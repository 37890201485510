import repair from '../static-images/repair.jpg'
import printing from '../static-images/printing.jpeg'
import camera from '../static-images/camera.jpg'
import computer from '../static-images/computers.jpeg'
import mobile from '../static-images/mobile.jpg'
import windows from '../static-images/windows2.jpg'

export const services_data = [
    {
      img: repair,
      title: "Computer Support",
      body: `Diagnose & Repair Reported PC hardware and software Issues. In addition to assisting with network setup/media 
      connectivity and resolving server issues.`,
    },
    {
      img: printing,
      title: "Copy Printing",
      body: `High quality printing of any document or graphics.`,
    },
    {
      img: camera,
      title: "Video Surviellance",
      body: `Camera Sale and Installations. As well as, Remote monitoring setup, DVR or Online Storage setup and solar lighting 
      sales and installation to enhance night recordings.`,
    },
    {
      img: computer,
      title: "Internet Cafe",
      body: `Browse in air-conditioned comfort with state of the art PCs and laptops.
      We are located at 62 Johns Lane, Kingston, Jamaica`,
    },
    {
      img: mobile,
      title: "Notebook/ tablet & LED TV repair",
      body: `Save money and fix it instead. We do motherboard repairs for notebook, tablets and TVs. As well as, LCD replacements.`,
    },
    {
      img: windows,
      title: "Software Fix and Upgrade",
      body: `Includes PC Operating system upgrade, Antivirus installation / upgrade, MS Office license installation, document & 
      program migration / backup`,
    },
]