import React from 'react'
import Layout from '../components/layout'
import FloatingCart from '../components/floating-cart'
import ResponsiveView from '../responsive/view'
import {Parallax, ParallaxLayer} from 'react-spring/renderprops-addons'
import { services_data } from '../common/hard-coded-data'
import { Card } from '@material-ui/core'
import ServicesContainer from '../components/services-components/services-container'
import Hero from '../components/hero'

const Company = () => {
    return (
        <Layout>
            <ResponsiveView render={isMobile => (
                <FloatingCart isMobile={isMobile} />
            )} />

            <ResponsiveView render={isMobile => (
                <Hero 
                    isMobile={isMobile}
                    page="service"
                    title="Delivering IT Services Using the Right Technology"
                    body="Concentrate on your core business and not IT issues." 
                />
            )} />

            <ResponsiveView render={isMobile => (
                <ServicesContainer isMobile={isMobile} />
            )} />
        
        </Layout>
    )
}

export default Company
