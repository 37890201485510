import React from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { services_data } from '../../common/hard-coded-data';
import { Grid, Card } from '@material-ui/core';
import { flexColCenterAll } from '../../common/format-styles';

interface PropTypes {
    isMobile: boolean;
    className?: string;
}

const ServicesContainer = (props: PropTypes) => {
    const { isMobile } = props;

    const styles = css(`
        padding: 5%;

        .gridItem {

            .gridItemContent {
                margin: auto;
                padding: 0 1%;
                width: 80%;

                h1 {
                    font-size: 1.8rem;
                    align-self: flex-start;
                }

                p {
                    font-size: .9rem;
                    align-self: flex-end;
                    width: 70%;
                    text-align: right;
                    margin-top: 5%;
                }
    
                .card {
                    width: 100%;
                    height: 20vw;
                    background-size: 100% 100%;
                }
            }
            
        }
    `);

    return (
        <Grid container component="section" css={styles}>
            {services_data.map((service, idx) => (
                <Grid item lg={6} md={6} sm={12} xs={12} className="gridItem" css={(idx + 1) % 2 === 0 && {paddingTop: '20vw'}}>
                    <div className="gridItemContent" css={flexColCenterAll}>
                        <h1>{service.title}</h1>
                        <Card css={{background: `url(${service.img})`}} className="card" />
                        <p>{service.body}</p>
                    </div>
                </Grid>      
            ))}
        </Grid>
    )
}

export default ServicesContainer